import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";

import { RequirementWorkflowType } from "../../Documents/resources/requirements/constants";
import { type HcpRequirement } from "../../Documents/types";
import { useGetHelloSignRequirement } from "./useGetHelloSignRequirement";

interface UseGetInstructionsAndFallbackUrlProps {
  selectedRequirement?: HcpRequirement;
}

export function useGetInstructionsAndFallbackUrl(props: UseGetInstructionsAndFallbackUrlProps) {
  const { selectedRequirement } = props;

  const isFallbackInstructionsTextEnabled = useCbhFlag(
    CbhFeatureFlag.HELLOSIGN_INSTRUCTIONS_TEXT_ENABLED,
    { defaultValue: false }
  );

  const {
    helloSignConfig,
    instructions: defaultInstructions,
    compositeConfig,
  } = selectedRequirement ?? {};

  const { getHelloSignConfig, isHelloSignRequirement } = useGetHelloSignRequirement({
    selectedRequirement,
  });
  const isCompositeRequirement =
    selectedRequirement?.requirementWorkflowType === RequirementWorkflowType.COMPOSITE;

  function getInstructions() {
    if (isFallbackInstructionsTextEnabled) {
      if (isHelloSignRequirement) {
        return helloSignConfig?.instructions;
      }

      if (isCompositeRequirement) {
        return compositeConfig?.instructions;
      }
    }

    return defaultInstructions;
  }

  function getFallbackStaticUrl() {
    const helloSignConfigFromRequirement = getHelloSignConfig();

    return isDefined(helloSignConfigFromRequirement) &&
      !helloSignConfigFromRequirement.isEmbeddedFlowEnabled
      ? helloSignConfigFromRequirement.fallbackStaticUrl
      : undefined;
  }

  return {
    fallbackStaticUrl: getFallbackStaticUrl(),
    instructions: getInstructions(),
  };
}
